@import "https://unpkg.com/open-props";
@import "https://unpkg.com/open-props/normalize.min.css";


body {
  background-color: rgb(41, 45, 54);
  overflow-x: hidden;
}


.time-left-free-game {
  background-color: rgb(69, 69, 69);
  position: absolute;
  font-size: 0.85em;
  color: #fff;
  bottom: 7.5%;
  width: calc(100% - 8px);
  left: 8px;
  padding-top: 4px;
  border-radius: 5px 5px 0 0;
  opacity: 0.8;
  text-align: center;
}

.goLeftSpan {
  z-index: 10;
  margin-top: min(125px, 12.5vw);
  position: absolute;
  margin-left: - min(25px, 2.5vw);
}

.goLeftButton {
  position: absolute;
  width: min(40px, 4vw);
  height: min(40px, 4vw);
  background-color: rgba(41, 45, 54, 0);

}

.goRightSpan {
  z-index: 10;
  margin-top: min(125px, 12.5vw);
  position: absolute;
  margin-left: min(935px, 93.5vw);
}

.goRightButton {
  position: absolute;
  width: min(40px, 4vw);
  height: min(40px, 4vw);
  background-color: #22a6b3;
  background-color: rgba(41, 45, 54, 0);
}

.splide-container {
  padding: 0;
  margin: 0;
  margin-top: -2em;
  width: 60%;
  min-width: min(1000px, 90vw);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.splide__arrow--next {
  right: -2em !important;
  background: none !important;
}

.splide__arrow--prev {
  left: -1.5em !important;
  background: none !important;
}

.splide__arrow svg {
  fill: #fff !important;
}

.media-scroller {
  color-scheme: dark;
  width: min(950px, 95vw);
  min-width: min(950px, 95vw);
  margin: auto;
  display: grid;
  gap: var(--size-0);
  grid-auto-flow: column;
  grid-auto-columns: 22%;
  padding: 0 var(--size-2) var(--size-2);
  overflow-x: hidden;
  scrollbar-color: blue;
  overscroll-behavior-inline: contain;
}

.media-element {
  /* position: relative;
  display: grid;
  grid-template-rows: min-content;
  padding: var(--size-3); */
}
.group-image {
  position: relative;
}

.group-image > .pochette {
  max-width: 200px;
  inline-size: 100%;
  aspect-ratio: 2 / 3;
  object-fit: cover;
  box-shadow: 0 2px 7px 1px black;
}

.group-image > .publisher {
  position: absolute;
  width: 19%;
  top: 0%;
  background-color: rgb(26, 24, 24);
  padding: 3px;
  padding-bottom: 2.2%;
  border-radius: 0 0 15% 15%;
  right: 3%;
}

.group-image > .status {
  text-align: center;
  font-size: 100%;
  position: absolute;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  left: 3%;
  top: 3%;
}

.snaps-inline {
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: var(--size-3);
}

.snaps-inline > * {
  scroll-snap-align: start;
}

@media only screen and (max-width: 800px) {
  .media-scroller {
    grid-auto-columns: 37%;
    overflow-x: auto;
  }


  .goLeftSpan {
    display: none;
  }

  .goRightSpan {
    display: none;
  }
}

.error404 {
    text-align: center;
    margin: 0 auto;
    padding: 20px;
    padding-top: 20vh;
    max-width: 600px;
}

.pError404 {
    text-align: center;
    margin: 0 auto;
    font-size: 1.5em;
    font-weight: 300;
    color: #CAD0D5;
    font-family: "Oswald";
    margin-bottom: 50px;
}

.aError404 {
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 1.3em;
    font-weight: 300;
    color: #CAD0D5;
    font-family: "Oswald";
    margin-bottom: 20px;
    text-decoration: underline;
    text-decoration-color: var(--underline);
}
header {
  background-color: #232426;
}

nav {
  display: flex;
  justify-content: space-between;
  width: 60%;
  min-width: min(1000px, 90vw);
  margin: 0 auto;
  align-items: center;
}

.centered-link {
  text-align: center;
  height: fit-content;
  color: white;
  text-decoration: none;
  font-size: 16px;
  padding: 0px 8px;
  border-radius: 4px;
  border: 1px solid white;
  opacity: 1;

  transition: opacity 0.2s ease-in-out;
}

.centered-link:hover {
  opacity: 0.64;
  /* border: 1px solid rgb(69, 118, 196); */
  /* color: rgb(69, 118, 196); */
}

.group-links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
  align-items: center;
}

.centered-link-logo {
  display: block;
  width: 36px;
  height: 36px;
}
.play-store {
  display: block;
  width: 80px;
  height: 32px;
}

.title-in-table {
    color: #fff;
    margin-top: -8px;
    margin-bottom: 14px;
    font-size: 44px;
    line-height: 40px;
    text-transform: uppercase;
    font-weight: bold;
    /* font-variant: small-caps; */
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    text-decoration: underline;
    text-decoration-color: var(--underline);
}

.inner-block-image-desc {
    width: 60%;
    min-width: min(1000px, 90vw);
    max-width: 800px;
    display: flex;
    align-items:center;
    text-align: left;
}

.container-table-description {
    padding-left: 2em;
}

.description {
    font-weight: 300;
    color: #CAD0D5;
    font-size: 1.2em;
    height: 150px;
    text-align:justify;
    text-align-last: center;
    word-break:keep-all;
}

.infos {
    font-size: 0.85em;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
    justify-content: space-evenly
}

.label-info {
    font-variant: small-caps;
    color: rgb(165, 165, 165);
}

table td {
    padding: 8px; 
}


.image-pochette-game {
    height: 300px;
    aspect-ratio: 2 / 3;
}

body {
    background: url(./../assets/bgImage/Minecraft.svg);
    /* background-color: #232426; */
    background-color: rgb(41,45,54)
}

/* header {
    background-color: rgb(18, 17, 17);
}

nav {
    width: 60%;
    min-width: min(1000px, 90vw);
    max-width: 800px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.navTitle2 {
    margin-top: 0;
} */

.logo-top {
    margin-top: 0.5em;
    margin-bottom: 0.3em;
    /* margin-left: -40px; */
    max-height: 40px;
    
}


.title-top {

    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
.block-title-home {
    margin: auto;
    min-width: 195px;
}
/* .block-title-home {
    margin-left: 0;
    width: 400px;
} */

.homeLink {
    text-decoration: none;
    color: #fff;
}

.plateform {
    max-width: max-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    color: #fff;
}

.plateform-item {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

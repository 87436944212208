.searchElem {
    display: flex;
    width: 20vw;
    min-width: 240px;
    height: 85px;
    background-color: rgb(48, 54, 66);
    text-align: center;
    vertical-align: middle;
    box-shadow: 0 0 2px 1px black;
    margin: auto;
}

.titleSearchGame {
    margin: auto;
    color: aliceblue;
}

.imgSearchGame {
    background-color: rgb(41, 45, 54);
    height: 100%;
    aspect-ratio: 2 / 3;
    object-fit: cover;
}
.link-search-preview{
    height: auto;
    min-width: 0;
    display: flex;
}

.link-search-preview:hover {
   
    text-decoration: underline;
	text-decoration-color: white;
	-webkit-text-decoration-color: white;
}
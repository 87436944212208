.contact-title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  color: white;
  margin-bottom: 48px;
}

.block {
  margin: 0 auto;
  padding: 12px 24px;
  border-radius: 5px;
  background-color: rgb(48, 54, 66);
  width: min(600px, 95vw);
}

.contact-p {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  color: white;
  text-align: center;

}



@media screen and (max-width: 600px) {
  .contact-title {
   width: 95%;
   font-size: 32px;
  }
  
  .contact-p {
    width: 95%;
  }
}
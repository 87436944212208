@font-face {
  font-family: "Oswald";
  src: url("./../assets/fonts/Oswald/static/Oswald-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Oswald";
  src: url("./../assets/fonts/Oswald/static/Oswald-ExtraLight.ttf");
  font-weight: 300;
}

#container {
  position: relative;
  width: 60%;
  min-width: min(1000px, 90vw);
  margin: auto;
  background-color: rgb(48, 54, 66);
  text-align: center;
  border-radius: 5px;
  margin-top: 2em;
  padding: 1%;
  max-width: 800px;
  content: "";
  clear: both;
  display: table;
}

:root {
  --underline: rgb(69, 118, 196);
}

iframe{
  width: 100%;
  height: 100%;
}

.product-details {
  position: relative;
  text-align: left;
  overflow: hidden;
  padding: 15px;
  padding-top: 1px;
  height: 100%;
  
}

#container .product-details h1 {
  display: inline-block;
  position: relative;
  font-size: 22px;
  margin: 0;
}

#container .product-details > .information {
  color: #CAD0D5;
  font-family: "Oswald";
  font-weight: 300;
  font-size: 19px;
  text-align:justify;
  word-break:keep-all;
  margin-top: 11px;
}

#container .article-bottom p {
  color: #CAD0D5;
  font-family: "Oswald";
  font-weight: 300;
  margin-left: 80px;
}

.article-bottom {
  position: absolute;
  right: 0%;
  bottom: 3%;
  width: 55%;

}
.report {
  float: right;
}

.product-image {
  width: 45%;
  overflow: hidden;
  height: 100%;
  display: inline-block;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  margin: auto;
  float: left;
}

#container img {
  width: 100%;
  height: 100%;
}

#highlightTitle {
  color: #fff;
  min-width: 80%;
  margin-top: -6px !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.6rem !important;
  transition: all 200ms ease-out;
}

#highlightTitle:hover ~ hr {
  width: 70%;
}

.separator-animated {
  position: absolute;
  width: 0px;
  height: 2px;
  background: var(--underline);
  border: var(--underline);
  margin: 0px;
  transition: width 400ms ease-out;
}


@media only screen and (max-width: 900px) {
  #container {
    min-width: 100%;
    border-radius: 5%;
    margin-top: 20px;
    padding-bottom: 14%;
  }

  .product-details {
    width: 100%;
    height: 100%;
  }
  #container .product-details h1 {
    font-size: 5vw;
    
  }
  #container .product-details > p {
    font-size: 3vw;
    
  }

  .product-image {
    display: block;
    width: 60%;
    margin-left: 20%;
    margin-bottom: 2vh;
    margin-top: 1vh;
    border-radius: 3%;
  }
}

.search-box {
  margin-top: 3px;
  width: fit-content;
  height: fit-content;
  position: relative;
}

.input-search {
  border-style: none;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: #22a6b3;
  padding-right: 40px;
  color: #fff;
}

.input-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search {
  top: 0;
  width: 50px;
  height: 40px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #ffffff;
  background-color: transparent;
  pointer-events: painted;
}

.btn-search ~ .input-search {
  margin: auto;
  min-width: 195px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.input-search:focus {
  width: 15vw;
  min-width: 200px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid #22a6b3;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.searchResults {
  z-index: 100;
  border-radius: 50px;
  position: absolute;
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  .search-box {
    min-width: 45vw;
    width: 45vw;
  }
  .btn-search:focus ~ .input-search {
    width: 45vw;
    min-width: 45vw;
  }

  .btn-search ~ .input-search {
    width: 42vw;
    min-width: 42vw;
  }
  .input-search:focus {
    width: 45vw;
    min-width: 45vw;
  }
}

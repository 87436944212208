@font-face {
    font-family: "Oswald";
    src: url("./../assets/fonts/Oswald/static/Oswald-Regular.ttf");
  }


body {
    margin: 0;
    padding: 0;
    font-family: "Oswald";
    background: url(./../assets/bgImage/Minecraft.svg);
}

/* header {
    background-color: rgb(36, 33, 33);
}

nav {
    width: 60%;
    min-width: min(1000px, 90vw);
    max-width: 800px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
} */

.navTitle {
    margin-top: 0.5em;
}

.logo-top {
    margin-top: 0.75em;
    float: left;
    width: 300px;

}


/* .block-title-home {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
} */

.loadMore {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    font-size: larger;
}